<template>
  <!-- 月租卡续费 -->
  <div class="box-card">
    <el-form :model="renewalsForm" :rules="renewalsFormRules" ref="renewalsFormRef" label-width="130px"
      class="demo-ruleForm">
      <div class="title" style="margin-top: 0;">基本信息</div>
      <el-divider></el-divider>
      <el-row :gutter="0">
        <el-col :span="12">
          <el-form-item label="车牌号：" style="margin-bottom:0px;"> {{ renewalsDetail.plate_number
          }}</el-form-item>
          <el-form-item label="卡类型：" style="margin-bottom:0px;"> {{ getWayName("CarOwnerCardType",
            renewalsDetail.car_owner_card_type) }}</el-form-item>
          <el-form-item label="开始时间：" style="margin-bottom:10px;">
            {{validDate(renewalsDetail.begin_time)}}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="停车场：" style="margin-bottom:0px;"> {{ renewalsDetail.parking_name }}</el-form-item>
          <el-form-item label="剩余天数：" style="margin-bottom:0px;">
            {{
              getDiffTime($moment(renewalsDetail.begin_time).format('YYYY-MM-DD HH:mm:ss'),$moment(renewalsDetail.end_time).format('YYYY-MM-DD HH:mm:ss')) +'天'
            }}
          </el-form-item>
          <!-- <el-form-item label="有效期：" style="margin-bottom:10px;"> {{ $moment(renewalsDetail.begin_time).format('YYYY-MM-DD')
            + " 至 " +  $moment(renewalsDetail.end_time).format('YYYY-MM-DD') }}</el-form-item> -->

          <el-form-item label="结束时间：" style="margin-bottom:10px;">
            {{validDate(renewalsDetail.end_time)}}
          </el-form-item>
        </el-col>
      </el-row>
          <!-- 充值方式 -->
          <el-form-item key="recharge_method" label="充值方式：">
            <el-select v-model="renewalsForm.month_pay_type" placeholder="请选择充值方式" @change="rechargeMethodChange">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
      <el-row :gutter="0">
        <el-col :span="12">
          <el-form-item label="开始时间：" prop="begin_time">
            <el-date-picker v-model="renewalsForm.begin_time" format="yyyy-MM-dd" type="date" placeholder="请选择开始时间"
              :picker-options="{ disabledDate: fnDisabledBeginDate, }" :disabled="!isExpire" @change="selectStartTime">
            </el-date-picker>
          </el-form-item>
          <!-- 应收金额 -->
          <el-form-item label="应收金额：">
            <!-- <el-input v-model="renewalsForm.should_month_amount" maxlength="8" :disabled="true"></el-input> -->
            <span>￥{{renewalsForm.should_month_amount?renewalsForm.should_month_amount.toFixed(2):'0.00'}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 按时间充值选择时间 -->
          <div class="timeMethod" v-if="this.renewalsForm.month_pay_type === '1'">
            <el-form-item label="结束时间：" prop="end_time">
              <el-date-picker v-model="renewalsForm.end_time" format="yyyy-MM-dd" type="date" placeholder="请选择结束时间"
                :picker-options="{ disabledDate: fnDisabledEndDate, }" @change="selectEndTime">
              </el-date-picker>
            </el-form-item>
          </div>
          <!-- 按月充值选择月 -->
          <div class="monthMethod" v-if="this.renewalsForm.month_pay_type === '2'">
            <el-form-item label="充值月数：" prop="month_count">
              <el-input v-model="renewalsForm.month_count" maxlength="8" placeholder="请输入充值月数"
                @input="(e) => (renewalsForm.month_count = validNumber(e))" @change="selectTotalMonth"></el-input>
                <span style="margin-left:15px;">月</span>
            </el-form-item>
          </div>
          <el-form-item label="实收金额：" prop="renew_amount">
            <el-input v-model="renewalsForm.renew_amount" maxlength="10" placeholder="请输入充值金额"
              @input="(e) => (renewalsForm.renew_amount = validNumber(e))"
              oninput="value=value.replace(/^([0-9-]\d*\.?\d{0,2})?.*$/,'$1')"
              ></el-input>
            <span style="margin-left:15px;">元</span>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="title">充值记录</div>
    <el-divider></el-divider>
    <el-table class="tablestyle" :data="renewalsDetail.paymentList" border>
      <el-table-column prop="plate_number" label="车牌号"></el-table-column>
      <el-table-column prop="parking_name" label="停车场" min-width="120"></el-table-column>
      <!-- <el-table-column label="卡类型">
            <template slot-scope="scope">
              {{ scope.row.card_type   ? getWayName("CarOwnerCardType", scope.row.card_type)   : "" }}
            </template>
          </el-table-column> -->
      <!-- <el-table-column prop="effective_time_interval" label="有效期" width="200">
          </el-table-column> -->
      <el-table-column label="开始时间">
        <template slot-scope="scope">{{ $moment(scope.row.begin_time).format('YYYY-MM-DD') }} </template>
      </el-table-column>
      <el-table-column label="结束时间">
        <template slot-scope="scope">{{ $moment(scope.row.end_time).format('YYYY-MM-DD') }} </template>
      </el-table-column>
      <!-- <el-table-column label="类型">
            <template slot-scope="scope">{{getWayName("CardStatus", scope.row.card_status)}}</template>
          </el-table-column> -->
      <!-- 实收金额 -->
      <el-table-column prop="renew_amount" label="应收金额">
        <template slot-scope="scope">{{ scope.row.should_month_amount.toFixed(2) }} </template>
      </el-table-column>
      <el-table-column prop="renew_amount" label="实收金额">
        <template slot-scope="scope">{{ scope.row.renew_amount.toFixed(2) }} </template>
      </el-table-column>
      <el-table-column label="操作日期" min-width="100px">
        <template slot-scope="scope">
          <span>{{ $moment(scope.row.update_time).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="operator_name" label="操作员"> </el-table-column>
    </el-table>
    <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="send" v-preventReClick>确认充值</lbButton>
  </div>
</template>

<script>
import request from '@/api/carmanager'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      // 续费表单
      renewalsForm: {
        card_id: '',
        begin_time: '',
        end_time: '',
        renew_amount: '', // 实收金额
        month_pay_type: '2', // 充值方式
        month_count: 1, // 充值月数
        should_month_amount: '', // 应收金额
        cost_scheme_amount: '' // 月租卡收费方案
      },
      renewalsFormRules: {
        begin_time: [
          { required: true, message: '请选择开始时间', trigger: 'blur' }
        ],
        end_time: [
          { required: true, message: '请选择结束时间', trigger: 'blur' }
        ],
        renew_amount: [
          { required: true, message: '请输入充值金额', trigger: 'blur' }
        ],
        // recharge_method: [
        //   { required: true, message: '请选择充值方式', trigger: 'blur' }
        // ],
        month_count: [
          { required: true, message: '请选择充值月数', trigger: 'blur' }
        ]
      },
      renewalsDetail: {},
      // 充值方式
      options: [{
        value: '1',
        label: '按时间'
      }, {
        value: '2',
        label: '按月'
      }],
      spanMonth: '', // 月份跨度
      isExpire: true // 是否过期
    }
  },
  computed: {
    ...mapGetters(['getWayName']),
    ...mapState('menuList', ['isRoutW'])

  },
  created () {
    this.fnGetMonthCardRenewalsPage()
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 获取页面数据
    async fnGetMonthCardRenewalsPage () {
      const res = await request.monthCardRenewalsPage({ card_id: this.id })
      if (res && res.Code === 200) {
        this.renewalsDetail = res.Data
        console.log(' 回显this.renewalsDetail', res.Data)
        this.isExpireCard(res.Data)
        // var dateTime = new Date(res.Data.end_time)
        // var nowTime = new Date()
        // if (dateTime > nowTime) {
        //   dateTime = dateTime.setDate(dateTime.getDate() + 1)
        //   //   console.log('dateTime', dateTime)
        //   // console.log('过期时间大于现在时间')
        //   this.renewalsForm.begin_time = new Date(dateTime)
        //   this.renewalsForm.end_time = new Date(dateTime + 86400000)
        //   const beginMonth = new Date(this.renewalsForm.begin_time).getMonth() + 1
        //   const beginYear = new Date(this.renewalsForm.begin_time).getFullYear()
        //   const endYear = new Date(this.renewalsForm.end_time).getFullYear()
        //   const endMonth = this.renewalsForm.end_time.getMonth() + 1
        //   // 回显应收金额计算时间跨度
        //   if (endYear - beginYear <= 0) {
        //     this.spanMonth = endMonth - beginMonth + 1
        //   } else if (endYear - beginYear === 1) {
        //     this.spanMonth = 12 - beginMonth + endMonth
        //   } else {
        //     this.spanMonth = (endYear - beginYear - 1) * 12 + (12 - beginMonth + endMonth)
        //   }
        //   this.renewalsForm.should_month_amount = res.Data.cost_scheme_amount * this.spanMonth
        //   this.renewalsForm.renew_amount = res.Data.cost_scheme_amount * this.spanMonth
        //   this.isExpire = false
        // } else {
        //   this.renewalsForm.begin_time = new Date()
        //   // console.log('过期时间小于现在时间')
        //   this.isExpire = true
        //   // console.log('this.renewalsForm.begin_time2', this.renewalsForm.begin_time)
        // }
      }
      // 默认按月充值一个月
      this.renewalsForm.should_month_amount = res.Data.cost_scheme_amount
      // this.renewalsForm.renew_amount = res.Data.cost_scheme_amount
      const endDay = this.renewalsForm.begin_time.getTime() + (86400000 * 30)
      this.renewalsForm.end_time = new Date(endDay)
      this.renewalsForm.cost_scheme_amount = res.Data.cost_scheme_amount // 该月租卡每月应收金额
    },
    // 开始时间限制
    fnDisabledBeginDate (time) {
      if (this.renewalsForm.end_time) {
        return (time.getTime() < Date.now() - 8.64e7) || (time.getTime() >= this.$moment(this.renewalsForm.end_time).format('x'))
      } else {
        //  如果没有后面的-8.64e6就是不可以选择今天的
        return time.getTime() < Date.now() - 8.64e7
      }
    },
    // 结束时间限制
    fnDisabledEndDate (time) {
      if (this.renewalsForm.begin_time) {
        return time.getTime() <= this.$moment(this.renewalsForm.begin_time).format('x')
      } else {
        //  如果没有后面的-8.64e6就是不可以选择今天的
        return time.getTime() < Date.now()
      }
    },
    // 续费请求
    send () {
      this.$refs.renewalsFormRef.validate((valid) => {
        if (valid) {
          if (parseFloat(this.renewalsForm.renew_amount) > 0) {
            this.requestMonthxufeiPoint()
          } else {
            this.$msg.warning('金额不能为0')
          }
        } else {
          return false
        }
      })
    },
    // 判断是否能续费
    async requestMonthxufeiPoint () {
      const res = await request.monthxufeiPoint({
        parking_id: this.renewalsDetail.parking_id,
        plate_number: this.renewalsDetail.plate_number,
        _id: this.renewalsDetail.card_id,
        end_time: this.renewalsForm.end_time,
        begin_time: this.renewalsForm.begin_time
      })
      if (res && res.Code === 200) {
        this.requestRenewMoney()
      } else if (res.Code === 302) {
        this.$confirm(res.Message, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.renewalsForm.begin_time = res.Data.time
          this.requestRenewMoney()
        }).catch(() => {
          this.requestRenewMoney()
        })
      }
    },
    // 判断卡片是否过期
    isExpireCard (data) {
      var dateTime = new Date(data.end_time)
      var nowTime = new Date()
      if (dateTime > nowTime) {
        dateTime = dateTime.setDate(dateTime.getDate() + 1)
        //   console.log('dateTime', dateTime)
        // console.log('过期时间大于现在时间')
        this.renewalsForm.begin_time = new Date(dateTime)
        this.renewalsForm.end_time = new Date(dateTime + 86400000)
        const beginMonth = new Date(this.renewalsForm.begin_time).getMonth() + 1
        const beginYear = new Date(this.renewalsForm.begin_time).getFullYear()
        const endYear = new Date(this.renewalsForm.end_time).getFullYear()
        const endMonth = this.renewalsForm.end_time.getMonth() + 1
        // 回显应收金额计算时间跨度
        if (endYear - beginYear <= 0) {
          this.spanMonth = endMonth - beginMonth + 1
        } else if (endYear - beginYear === 1) {
          this.spanMonth = 12 - beginMonth + endMonth
        } else {
          this.spanMonth = (endYear - beginYear - 1) * 12 + (12 - beginMonth + endMonth)
        }
        // this.renewalsForm.should_month_amount = data.cost_scheme_amount * this.spanMonth
        // this.renewalsForm.renew_amount = data.cost_scheme_amount * this.spanMonth
        this.isExpire = false
      } else {
        this.renewalsForm.begin_time = new Date()
        // console.log('过期时间小于现在时间')
        this.isExpire = true
        // console.log('this.renewalsForm.begin_time2', this.renewalsForm.begin_time)
      }
    },
    // 续费请求
    async requestRenewMoney () {
      this.renewalsForm.card_id = this.renewalsDetail.card_id
      //   const endReal = new Date(this.renewalsDetail.end_time)
      //   const timerow = new Date(endReal)
      //   console.log('endReal', endReal, timerow)
      const res = await request.renewMonthcard(this.renewalsForm)
      if (res && res.Code === 200) {
        this.$refs.renewalsFormRef.resetFields() // 重置表单
        this.fnGetMonthCardRenewalsPage()
      }
      // this.renewalsForm.should_month_amount = 0
      this.renewalsForm.month_count = 1
    },
    // 充值方式改变
    rechargeMethodChange () {
      // 改变方式清除数据
      // console.log(this.renewalsForm.month_pay_type)
      this.renewalsForm.end_time = ''
      this.renewalsForm.renew_amount = ''
      if (this.renewalsForm.month_pay_type === '2') {
        this.renewalsForm.month_count = 1
        this.renewalsForm.should_month_amount = this.renewalsForm.cost_scheme_amount * this.renewalsForm.month_count
        // this.renewalsForm.renew_amount = this.renewalsForm.cost_scheme_amount * this.renewalsForm.month_count
        const endDay = this.renewalsForm.begin_time.getTime() + (86400000 * 30 * 1)
        this.renewalsForm.end_time = new Date(endDay)
      } else {
        if (this.isExpire) {
          this.renewalsForm.month_count = 0
          this.renewalsForm.should_month_amount = 0
        } else {
          this.renewalsForm.end_time = new Date(Date.parse(this.renewalsForm.begin_time) + 86400000)
          const beginMonth = new Date(this.renewalsForm.begin_time).getMonth() + 1
          const beginYear = new Date(this.renewalsForm.begin_time).getFullYear()
          const endYear = new Date(this.renewalsForm.end_time).getFullYear()
          const endMonth = this.renewalsForm.end_time.getMonth() + 1
          if (endYear - beginYear <= 0) {
            this.spanMonth = endMonth - beginMonth + 1
          } else if (endYear - beginYear === 1) {
            this.spanMonth = 12 - beginMonth + endMonth
          } else {
            this.spanMonth = (endYear - beginYear - 1) * 12 + (12 - beginMonth + endMonth)
          }
          this.renewalsForm.should_month_amount = this.renewalsForm.cost_scheme_amount * this.spanMonth
          // this.renewalsForm.renew_amount = this.renewalsForm.cost_scheme_amount * this.spanMonth
        }
      }
    },
    // 按时间充值 选择月卡结束时间 ----------
    selectEndTime (value) {
      // console.log('开始时间', this.renewalsForm.begin_time)
      console.log('结束时间', value)
      const start = this.$moment(this.renewalsForm.begin_time).format('YYYY-MM-DD')
      let days = ''
      if (value) {
        const end = this.$moment(value).format('YYYY-MM-DD ')
        days = this.$moment(end).diff(start, 'days')
        console.log(start, end, days)
      } else {
        days = 0
      }

      // 应收金额 = 月份跨度 * 月租卡收费方案
      const calculate = (this.renewalsForm.cost_scheme_amount / 30) * 1000 * days
      this.renewalsForm.should_month_amount = (calculate / 1000)
      // 实收金额随着改变默认显示
      this.renewalsForm.renew_amount = (days * (this.renewalsForm.cost_scheme_amount / 30)).toFixed(2)
    },
    // 选择按时间收费--开始时间
    selectStartTime () {
      // 选择开始时间清除结束时间
      this.renewalsForm.end_time = ''
      // 选择开始时间清除充值月份
      this.renewalsForm.month_count = ''
    },
    // 选择按月方案 充值月数变化
    selectTotalMonth (value) {
      // 应收金额 = 月数 * 月租卡收费方案
      this.renewalsForm.should_month_amount = value * this.renewalsForm.cost_scheme_amount
      // 实收金额随着改变默认显示
      this.renewalsForm.renew_amount = (value * this.renewalsForm.cost_scheme_amount).toFixed(2)
      // 按月充值后的结束时间
      const endDay = this.renewalsForm.begin_time.getTime() + (86400000 * 30 * value)
      this.renewalsForm.end_time = new Date(endDay)
    },
    // 相差多少天
    getDiffTime (start, end) {
      const nowDate = this.$moment().format('YYYY-MM-DD HH:mm:ss')
      console.log(start, end, nowDate)
      if (this.$moment(end).isBefore(nowDate)) {
      // 如果结束日期在今天之前
        return 0
      } else {
        let days = 0
        if (this.$moment(start).isBefore(nowDate)) {
        // 判断如果开始时间已过
          days = this.$moment(end).diff(this.$moment(nowDate), 'days')
        } else {
        // 判断开始时间还未开始
          days = this.$moment(end).diff(this.$moment(start), 'days')
        }
        return days
      }
      // var subtime = (new Date(newdate) - new Date(olddate)) / 1000 // 计算时间差,并将毫秒转化为秒
      // var days = parseInt(subtime / 86400) // 天  24*60*60*1000
      // return days
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }
}
</script>

<style scoped lang="less">
.title {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #000;
}

.tablestyle {
  /deep/ .el-table__body-wrapper {
    max-height: 180px;
  }
}

.demo-ruleForm {
  overflow: auto;
  /deep/ .el-input {
    width: 300px;
  }
  /deep/ .el-select {
    width: 300px;
  }
}

.box-card {
  position: relative;
  padding-bottom: 30px;
  margin-top: 0px !important;

  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
  }
}
</style>
